import { NormalizedFieldEntry } from '~/types/sourceValue'

export const NORMALIZED_FIELD_DEFINITION: {
  [key in NormalizedFieldEntry]?: {
    definition: string
  }
} = {
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_TYPE__IS_PLANNED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_TYPE__IS_PLANNED}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED_IN_EGAPRO_INDEX]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED_IN_EGAPRO_INDEX}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_REAL]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_REAL}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.MOBILITY_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.MOBILITY_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.DEPARTURE_REASON_TYPE__IS_REGRETTED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.DEPARTURE_REASON_TYPE__IS_REGRETTED}`
  },
  [NormalizedFieldEntry.DEPARTURE_REASON_CATEGORY__IS_REAL]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.DEPARTURE_REASON_CATEGORY__IS_REAL}`
  }
}
